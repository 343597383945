<template>
  <ul class="border border-gray-200 w-full rounded-lg mb-4" v-if="listItems.length">
    <li
      v-for="(item, index) in listItems"
      v-bind:key="item.id"
      class="px-2 py-2 border-gray-200 "
      v-bind:class="{ 'border-b': index != listItems.length - 1 }"
    >
      <slot v-bind:item="item"></slot>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'List',
  props: { items: Array },
  computed: {
    listItems() {
      const addMissingId = (item, index) => {
        return { ...item, id: item.id || index };
      };

      return this.items.map(addMissingId);
    },
  },
};
</script>
