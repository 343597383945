<template>
  <main class="screen default">
    <div class="screen-header">
      <h2 class="screen-title xs:mb-2 mb-0">{{ $t('history.heading') }}</h2>
      <button @click="clearHistory" class="btn faded">{{ $t('history.button_clear') }}</button>
    </div>

    <List :items="history">
      <template v-slot="{ item }">
        <span class="flex items-start">
          <span class="mr-2">{{ item.emoji }}</span>
          <span class="text-sm" v-html="item.title"> </span>
        </span>
      </template>
    </List>
  </main>
</template>

<script>
import { reverse } from 'ramda';
import List from '@/components/List';
import { CLEAR_HISTORY_RECORDS } from '@/store/mutation-types';
import { HISTORY_TYPE_ACTIVITY_REMOVED } from '@/domain/history-types';

function presentHistoryRecord(record) {
  switch (record.type) {
    case HISTORY_TYPE_ACTIVITY_REMOVED:
      return {
        emoji: '🐬',
        title: this.$t('history.activity_removed', { ActivityTitle: record.data.activity.title }),
      };

    default:
      return {
        emoji: '⚠️',
        title: record.type,
      };
  }
}

export default {
  name: 'HistoryScreen',
  components: {
    List,
  },
  methods: {
    clearHistory() {
      // TODO: build undo
      if (confirm(this.$t('history.confirm_clear_history'))) {
        this.$store.commit(CLEAR_HISTORY_RECORDS);
      }
    },
  },
  computed: {
    history() {
      return reverse(this.$store.state.history).map(presentHistoryRecord.bind(this));
    },
  },
};
</script>
